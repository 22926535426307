<template>
    <div style="padding: 0 16px;">
        
        <b-card-title v-if="type === 1" style="font-size: 20px; font-weight: 600; font-style: normal;">{{$t('cloud.cloud')}}</b-card-title> 
        <b-card-title v-else style="font-size: 20px; font-weight: 600; font-style: normal;">{{$t('miners.miners')}}</b-card-title> 

        <div style="overflow-x: scroll; margin-bottom: 20px;">
            <b-button-group>
                <div v-for="(status, index) in statuses" :key="index">
                    <b-button  @click="setStatus(status.value)" :variant="filter.status === status.value ? 'active' : 'inactive' " size="sm" style="height: 36px; white-space: nowrap;">{{ $t(status.display) }}</b-button>
                    <div v-if="index < statuses.length - 1" class="delimiter"></div>
                </div>
            </b-button-group> 
        </div>
        
        <b-card no-body v-if="statistic.length > 0" >
            <b-card-body class="p-0">
                <b-row  style="border-radius: 10px; padding: 12px;">
                   <b-col>
                       <p>{{$t('statistics.most-profitable-contract')}} <b-link :to="{ name: 'user-contract-details', params: { id: statistic[0].guuid } }">{{ statistic[0].guuid }}</b-link></p>
                       <b-progress :max="100">
                           <b-progress-bar v-for="(item,index) in statistic" :key="index" label="" :value="item.percent" :style="`background-color: ${colors[index]}`"></b-progress-bar>
                       </b-progress>
                   </b-col>
               </b-row>
            </b-card-body>
        </b-card>
        
        <b-card  v-for="(item, index) in contracts.items" v-ripple @click="$router.push({ name: 'user-contract-details', params: { id: item.guuid } })" :key="`contract:${item.id}:${index}`" no-body>
            <b-card-body style="padding: 15px 20px;">
                <div class="d-flex justify-content-between" style="margin-bottom: 12px;">
                    <div> {{ item.guuid }} </div>
                    <!--<div>-->
                            <div v-if="item.status === 1" style="min-width: 75px;">
                               <div class="d-flex justify-content-between">
                                   <b-progress
                                       style="flex-grow: 1; margin: auto 0; "
                                       :value="getCompletePercent(item)"
                                       max="100"
                                       height="12px"
                                       variant="primary"
                                   />

                                   <span class="text-muted mt-auto mb-auto" style="font-size: 12px; margin-left: 3px;">
                                       {{ getCompletePercent(item).toFixed(1) }}%
                                   </span>

                               </div>
                           </div>
                           <div v-else-if="item.status === 0">
                               <b-badge variant="danger">{{$t('contracts.statuses.unpaid')}}</b-badge>
                           </div>
                           <div v-else-if="item.status === 3">
                               <b-badge variant="primary" style="background-color: #E4EAFD; color: #426FF6; word-break: normal; word-wrap: break-word;">
                                   {{$t('contracts.statuses.start')}} {{ Utils.daysAgo( item.start_at ) }}
                               </b-badge>
                           </div>
                            <div v-else-if="item.status === 6">
                                <b-badge variant="secondary">
                                    {{$t('contracts.statuses.archive')}}
                                </b-badge>
                            </div>  
                            <div v-else-if="item.status === 7">
                                <b-badge variant="secondary">
                                    {{$t('contracts.statuses.pause')}}
                                </b-badge>
                            </div>
                            <div v-else-if="item.status === 4"> 
                                <b-badge variant="success">
                                    {{$t('contracts.statuses.finish')}}
                                </b-badge>
                        </div>
                    <!--</div>-->
                </div>
                
                <div class="d-flex justify-content-between with-seperator">
                    <div class="text-muted text-uppercase">{{$t('statistics.net-profit')}}</div>
                    <div>{{ getTotalProfit(item.current_balance) }}<span class="text-muted">{{ ticker }}</span></div>
                </div>
                <div class="separator" style="margin: 8px 0;" />
                <div class="d-flex justify-content-between">
                    <div class="text-muted text-uppercase">{{$t('tables.hashrate')}}</div>
                    <div>{{ item.ths.formatMoney(2,' ', ' ') }} <span class="text-muted">TH/s</span></div>
                </div>
                <div class="separator" style="margin: 8px 0;" />
                <div class="d-flex justify-content-between" style="padding: 4px 0;">
                    <div class="text-muted text-uppercase">
                        <i18n path="tables.count-miners" tag="span">
                        </i18n>
                    </div>
                    <div>{{ item.miners_count }} <span class="text-muted">{{$t('contracts.modal.piece')}}</span></div>
                </div>
                <div class="separator" style="margin: 8px 0;" />
                <div class="d-flex justify-content-between" style="padding: 4px 0;">
                    <div class="text-muted text-uppercase">{{$t('tables.investment')}}</div>
                    <div>{{ convertUSDtoBTC(item.investments).toFixed(5) }} <span class="text-muted">BTC</span></div>
                </div>
                
            </b-card-body>
        </b-card>

        
        <b-card-body class='d-flex flex-column pt-0' v-if='contracts.items.length === 0' >
            <div class="d-flex flex-column justify-content-center">
                <div class="nodata-icon ml-auto mr-auto"></div>
                <p v-if="!filter.status" class="text-muted mt-1 ml-auto mr-auto text-center">
                   {{$t('contracts.dont-have-contacts')}}
                </p>
                <p v-else class="text-muted mt-1 ml-auto mr-auto text-center">
                    {{$t('contracts.no-data')}}
                </p>
            </div>
        </b-card-body>

       
    </div>
</template>

<script>
    
    import User from "@/modules/user"
    import Blockchain from "@/modules/blockchain";
    import Utils from "@/modules/utils/"

    export default {

        data() {
            return {
                
                Utils,
                
                is_loading: false,
               
                filter: {
                    status: null
                },
                
                contracts: {
                    current_page: 0,
                    has_more_pages: false,
                    last_page: 0,
                    total_count: 0,
                    items: []
                },
                
                statuses: [
                    { value: null, display: "tabs.all" },
                    { value: "inwork", display: "tabs.in-process" },
                    { value: "nopay", display: "tabs.unpaid" },
                    { value: "complete", display: "tabs.completed" },
                    { value: "archive", display: "tabs.archive" }
                ],
                
                statistic: [],
                
                colors: [ '#46BCAA', '#FFCF52', '#4D69FA', '#F35421', '#F19C99' ],
                
                fields: [
                    { key: "guuid", label: "NO" },
                    { key: "ths", label: "Мощность" },
                    { key: "total_balance", label: "Заработок" },
                    { key: "is_active", label: "Статус" },
                    { key: "progress", label: "" },
                ],
                
                
            }
        },
        props: {
            type: Number
        },
        methods: {
            
            convertUSDtoBTC( value ) {
                return ( value / Blockchain.tickers.values['USD'].last );
            },
            
            setStatus( value ) {
                this.filter.status = value;
            },
            
            getCompletePercent( contract ) {
                if( this.type === 2 ) {
                    // let percent = ( (Math.floor(Date.now() / 1000)) / contract.end_at );
                    // return  100 - (percent * 100) ;

                    return 100 * ((Math.floor(Date.now() / 1000)) - contract.start_at) / (contract.end_at - contract.start_at)
                }
                return ((contract.total_balance * Blockchain.tickers.values['USD'].last ) / this.getPeriodProfitUSD( contract )) * 100;     
            },
            
            getPeriodProfitUSD( contract ) {
                return (((84600 * 30 * contract.period * User.contracts.values.R * ( User.contracts.values.H * contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * Blockchain.tickers.values["USD"].last) ;                
            },

            getTotalProfit( value ) {
                return ( value * Blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
            },
            
            get() {
                
                this.is_loading = true;
                
                this.$request.get("contracts.list", this.params ).then( result => {
                    
                    this.contracts.current_page = result.current_page;
                    this.contracts.has_more_pages = result.has_more_pages;
                    this.contracts.last_page = result.last_page;
                    this.contracts.total_count = result.total_count;
                    this.contracts.items = result.items;
                    
                    this.is_loading = false;
                    
                });                
            },
            
            
            handleScroll( e ) {
                               
                               
                if(!this.contracts) return;
                if(!this.contracts.has_more_pages) return;
                if( this.is_loading || this.is_loading_more_items ) return;
                
                let el = document.body;
                let doc = document.documentElement;
                let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
                               
                if( top + el.offsetHeight > ( el.scrollHeight - 100 ) ) {
                    
                  let params = this.params;
                  
                  params['page'] = this.contracts.current_page + 1;
                  
                  this.is_loading_more_items = true;
                  
                  this.$request.get("contracts.list", params ).then( result => {
                       this.contracts.has_more_pages = result.has_more_pages;
                       this.contracts.current_page = result.current_page;
                       this.contracts.items.push( ...result.items );
                       this.is_loading_more_items = false;                       
                  });
                  
                }
                
            },
            
            
            getStatistic() {
                this.$request.get("contracts.getSimpleStat", {
                    type: this.type
                }).then( result => {
                    this.statistic = result;
                });
            }
            
        },
        
        created() {
            window.addEventListener("scroll", this.handleScroll );
        },
        
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll );
        },
        
        computed: {
            
            ticker() {
                return User.getSymbol();
            },
            
            params() {
                return {
                    type: this.type,
                    status: this.filter.status
                }
            }
        },
        components: {

        },
        watch: {
            "filter.status" : function() {
                this.get();
            }
        },
        mounted() {
            this.get();
            this.getStatistic();             
        }

    }

</script>