<template>

    <div>
        <showAt breakpoint="large">
            <desktop :type="type" />
        </showAt>
        <showAt breakpoint="medium">
            <tablet :type="type" />
        </showAt>
        <showAt breakpoint="small">
            <mobile :type="type" />
        </showAt>
    </div>
    
</template>

<script>
    
    import desktop from "./desktop"
    import tablet from "./tablet"
    import mobile from "./mobile"

    export default {

        data() {
            return {}
        },
        props: {
            type: Number
        },
        methods: {

        },
        components: {
            desktop,
            tablet,
            mobile
        },
        watch: {

        },
        mounted() {

        }

    }

</script>